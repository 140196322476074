<template>
  <div>
    <a-modal
      v-model="visibleModalAddEmployee"
      class="modal-add-employee"
      title="Tambah Pegawai"
      @cancel="toggleModalAddEmployee"
      :destroyOnClose="true"
    >
      <add-employee
       :dataShift="manageEmployeeData"
       :employeeColumns="employeeColumns"
       @close-modal="toggleModalAddEmployee"
      />
      <template slot="footer">
        <a-button class="d-none m-0" size="large" key="back" @click="toggleModalAddEmployee">
          Tutup
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex">
      <div class="mr-3">
          <a-input
            v-model="search"
            @keyup="searchEmployee"
            size="large"
            placeholder="Cari pegawai..."
          />
        </div>
      <div class="ml-auto">
        <a-button
          @click.prevent="removeShiftEmployeeSelected"
          :disabled="!selectedRowShiftEmployee.length"
          class="ml-3"
          type="danger"
          size="large"
          :loading="loadingDeleteShiftEmployee"
        >
          <a-icon v-if="!loadingDeleteShiftEmployee" type="delete" /> {{loadingDeleteShiftEmployee ? 'Menghapus...' : 'Hapus Shift Pegawai Terpilih'}}
        </a-button>
        <a-button
          @click.prevent="toggleModalAddEmployee"
          class="ml-3"
          type="primary"
          size="large"
        >
          <a-icon type="plus" />Tambah Pegawai
        </a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="employeeColumns"
        :data-source="shiftEmployeeDataTable"
        :pagination="paginationShiftEmployee"
        :loading="loadingTableShiftEmployee"
        :row-selection="rowSelection"
        @change="handleTableChange"
        bordered
      >
        <div slot="jenis_kelamin" slot-scope="value">
          <span v-if="value"> {{value}}</span>
          <span v-else>-</span>
        </div>
        <div slot="unit" slot-scope="value">
          <span v-if="value"> {{value.nama}}</span>
          <span v-else>-</span>
        </div>
        <div slot="jabatan" slot-scope="value">
          <template v-if="value.length">
            <a-tag
              v-for="item in value"
              :key="item.id"
              color="geekblue"
            >
            {{ item.jabatan.nama.toUpperCase() }}
          </a-tag>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const addEmployee = () => import('@/components/app/AdminHR/Shift/ManageEmployee/AddEmployee')
const employeeColumns = [
  {
    title: 'ID Pegawai',
    dataIndex: 'nik',
    key: 'nik',
    // width: 250,
    scopedSlots: { customRender: 'nik' },
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Jenis Kelamin',
    dataIndex: 'jenis_kelamin',
    key: 'jenis_kelamin',
    // width: 200,
    sorter: true,
    scopedSlots: { customRender: 'jenis_kelamin' },
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    // width: 200,
    scopedSlots: { customRender: 'unit' },
  },
  {
    title: 'Jabatan',
    dataIndex: 'jabatan',
    key: 'jabatan',
    // width: 200,
    scopedSlots: { customRender: 'jabatan' },
  },
]
export default {
  name: 'ManageEmployee',
  components: {
    addEmployee,
  },
  props: {
    manageEmployeeData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employeeColumns,
      shiftEmployeeDataTable: [],
      selectedRowShiftEmployee: [],
      paginationShiftEmployee: {},
      visibleModalAddEmployee: false,
      loadingTableShiftEmployee: false,
      loadingDeleteShiftEmployee: false,
      search: '',
    }
  },
  methods: {
    removeShiftEmployeeSelected() {
      console.log(this.selectedRowShiftEmployee)
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div class="d-flex flex-column"><p>Apakah anda yakin ini menghapus {this.selectedRowShiftEmployee.length} data shift pegawai?</p><p class="text-danger mt-4">*Perubahan ini akan mempengaruhi sistem absensi pegawai</p></div>
        ),
        onOk: () => {
          this.loadingDeleteShiftEmployee = true
          this.$store.dispatch('shift/DELETE_SHIFT_EMPLOYEE_SELECTED', { dataPegawai: this.selectedRowShiftEmployee })
            .then(isSuccess => {
              this.loadingDeleteShiftEmployee = false
              this.fetchDataShiftEmployee()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description: `${this.selectedRowShiftEmployee.length} data shift pegawai berhasil di hapus`,
                })
                this.selectedRowShiftEmployee = []
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'data shift pegawai gagal di hapus',
                })
                this.selectedRowShiftEmployee = []
              }
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Hapus Sekarang',
        cancelText: 'Batal',
      })
    },
    toggleModalAddEmployee() {
      this.visibleModalAddEmployee = !this.visibleModalAddEmployee
      if (!this.visibleModalAddEmployee) {
        this.fetchDataShiftEmployee()
      }
    },
    searchEmployee() {
      this.fetchDataShiftEmployee({
        search: this.search,
      })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.paginationShiftEmployee }
      let order = 'ASC'
      let sortBy = ''
      pager.current = pagination.current
      this.paginationShiftEmployee = pager
      if (sorter) {
        sortBy = sorter.field
        // console.log(sorter)
        if (sorter.order === 'ascend') {
          order = 'ASC'
        } else if (sorter.order === 'descend') {
          order = 'DESC'
        }
      }
      this.fetchDataShiftEmployee({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    fetchDataShiftEmployee(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTableShiftEmployee = true
      const id = this.manageEmployeeData.id
      this.$store.dispatch('shift/FETCH_SHIFT_EMPLOYEE', { id, page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          // console.log(res)
          this.loadingTableShiftEmployee = false
          const pagination = { ...this.paginationShiftEmployee }
          pagination.total = res.total
          this.paginationShiftEmployee = pagination
          this.shiftEmployeeDataTable = res.pegawai.map(el => {
            return {
              key: el.id,
              id: el.id,
              nik: el.guru.nik,
              nama: el.guru.nama,
              jenis_kelamin: el.guru.jenis_kelamin,
              unit: el.guru.unit,
              jabatan: el.guru.jabatan_pegawais,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataShiftEmployee()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
          this.selectedRowShiftEmployee = selectedRows
        },
        // getCheckboxProps: record => ({
        //   props: {
        //     disabled: record.key === 34, // Column configuration not to be checked
        //     name: record.name,
        //   },
        // }),
      }
    },
  },
}
</script>

<style>

</style>
